import * as React from 'react'
import { Link } from 'gatsby'
import { container } from './layout.module.css'
import { Helmet } from 'react-helmet'
import {Navbar, Nav, Container} from 'react-bootstrap'
// import NavDropdown from 'react-bootstrap/NavDropdown'

import bCyberLogo from '../images/bcyber-logo.svg'

const Layout = ({ pageTitle, children }) => {
  return (
    // <div className={container}>
    <div className="container">
      <Helmet>
        <title>{pageTitle + " | bCyber"}</title>
      </Helmet>
      <Navbar expand="lg" variant="dark">
        <Container>
        <Navbar.Brand>
          <Link to="/" classNames="">
            <img src={bCyberLogo} alt="bCyber Logo" class="d-inline-block align-text-top ms-3" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="main-nav" />
        <Navbar.Collapse id="main-nav">
          <Nav className="me-auto">
            <Link className="nav-link" to="/" activeClassName="active">Home</Link>
            <Link className="nav-link" to="/kontakt" activeClassName="active">Kontakt</Link>
            {/* <NavDropdown title="Projekte" id="basic-nav-dropdown" menuVariant='dark'>
              <NavDropdown.Item href="https://www.blocktrainer.de" target="_blank" rel="noreferrer">Blocktrainer</NavDropdown.Item>
              <NavDropdown.Item><Link to="/404">Leezenflow</Link></NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>
      {children}
      <div className="row">
        <div className="col text-center pt-5 footer small"><Link to="/impressum">Impressum</Link>&nbsp;|&nbsp;<Link to="/datenschutz">Datenschutz</Link></div>
      </div>
    </div>
  )
}

export default Layout